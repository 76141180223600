body, html{
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.menu {
  width: 100%;
  padding: 10px 30px 0px 30px;
  height: 79px !important;
  /* background-color: rgba(0,0,0,0.8) !important; */
  /* box-shadow: 0 2px 4px 0 rgba(0,0,0,0.11) !important; */
}

.menu_app{
  color: white !important;
}
.logo {
  height: 37.96px !important;
  width: 124px !important;
}

.item_menu {
  margin: 0px 30px 0px 30px;
  color: #F8F8F8;
  font-family: Poppins !important;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
  padding: 15px
}

.item_menu:hover{
  color:#6D7278 !important;
  background-color: white !important;
}

.imagenHeader{
  height: 750px;
  width: 100%;
  background-image: url('./assets/img/header.png');
  background-repeat: no-repeat;
  background-size: cover;

}
.imagenNosotrosInicio{
  height: 779px;
  width: 100%;
  background-image: url('./assets/img/foto_compu.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.imgTituloNosotrosInicio{
  height: 65px;
  width: 210px;
}
.parrafoNosotrosInicio{
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 39px;
}
.tituloHeader{
  color: #7BBB80;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
}
.SubTituloHeader{
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 57px;
  text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}
.parrafoHeader{
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
}
.containerHeader{
  width: 500px;
  padding: 200px 10px 0px 50px;
}
.containerNosotrosInicio{
  width: 500px;
  padding: 300px 10px 0px 100px;
}
.card_header {
  height: 320px;

  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(214,214,214,0.5);
  margin:0px 10px 50px 10px !important;
}

.titulo_card_header {
  height: 25px;
  width: 57px;
  color: #004A73;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
}

.descripcion_card_header {
  width: 288px;
  color: #CECECE;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
.iconos_header {
  height: 50px;
  width: 15px;
  color: #00A58E;
  font-family: "Font Awesome 5 Free";
  font-size: 30px;
  letter-spacing: 0;
  line-height: 30px;
}
.container_servicios_cards{
margin-left: 20px !important;
}

.container_mision{
  background-repeat: no-repeat;
  padding: 30px;
  width: 100%;
}
.imagen_mision{
float: right;
}

.container_vision{
  background-repeat: no-repeat;
  padding: 30px;
  width: 100%;
}

.container_vision_texto{
  padding-top: 100px;
}
.container_mision_texto{
  padding-top: 100px;
  padding-left: 30px;
}

.titulo{
  height: 50px;
  font-weight: bold !important;
  color: #00A58E;
  font-family: Poppins;
  letter-spacing: 0;
  line-height: 27px;
}
.subtitulo{
  /* height: 110px; */
  width: 100%;
  color: #424242;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 10px;
}
.parrafo {
  width: 100%;
  color: #6D7278;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  text-align: justify;
}
.imagen_vision,.imagen_mision{
height: 550px;
width:500px;
}
.titulo_impacto {
  height: 25px;
  width: 83px;
  color: #00A58E;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
}
.impactoLineas{
  border-left: 1px solid #979797;
  height: 80px;
  position: static;
}
.subtitulo_impacto {
  height: 150px;
  color: #424242;
  font-family: Poppins;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
}
.textImpacto{
  display: flex;
  align-items: center;
  justify-content: center;
}
.containerImpacto{
  margin : 250px 0px 250px 0px;
  text-align: center;
}
.numero {
  height: 120px;
  width: 359px;
  color: #00A58E;
  font-family: Poppins;
  font-size: 80px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 150px;
  text-align: center;
}
.subtitulo_numero{
    height: 40px;
    width: 359px;
    color: #CCCCCC;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 46px;
    text-align: center;
}
.linea_vertical{
  width: 1px; /* Line width */
  background-color: black; /* Line color */
  height: 50%; /* Override in-line if you want specific height. */
  float: right;
}
.linea_verde{
  height: 70px; 
  width:100%;
  background: #C9DB8D;
}
.icono_linea{
    height: 110px;
    width: 110px;
}
.container_servicios{
  text-align: center;
  box-sizing: border-box;
  height: 240px !important;
  width: 261px !important;
  border: 1px solid #E0DEDD;
  border-radius: 8px;
  background-color: #FFFFFF;
  padding-top: 30px;
  cursor: pointer;
}
.container_servicios:hover{
  box-shadow: 0 0 20px #000000;
}

.titulo_modal{
  background-color: #004A73;
  color: white;
  font-family: Poppins;
}
.containerIconoServicio{
  height: 132px;
  width: 132px;
  border-radius: 50% !important;
  background-color: #FFFFFF;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.3);
  margin: 0 auto;
}

.iconos_servicios{
  background-color: #FFFFFF;
  height: 100%;
  width: 100%;
  border-radius: 50% !important;
}
.titulo_card_servicio{
  padding-top:30px;
  color: #004A73;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.58px;
  line-height: 16px;
  text-align: center;
}

.footer{
  margin-top: 150px !important;
  padding: 20px;
  min-height: 332px;
  background-color: #F8F8F8;
  margin: 0 auto;
}
.logo_footer{
  height: 56px;
  width: 250px;
}
.icon_footer{
  margin: 10px;
}
.descripcion_footer{
  height: 62px;
  width: 242px;
  color: #6D7278;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 18px;
  text-align: justify;
}
.navegacion-del-sitio {
  height: 28px;
  width: 295px;
  color: #424242;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
}
.nosotros-servicios-c{
  width: 100%;
  color: #424242;
  font-family: Poppins;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: none;
}
.nosotros-servicios-c-link{
  padding: 10px;
  width: 100%;
  color: #424242;
  font-family: Poppins;
  font-size: 16px;
  text-decoration: none;
}
.nosotros-servicios-c-link:hover {
 color: white;
  background-color: #004A73 !important;
}
.icon_footer_contacto{
  margin-right: 10px;
}
.btn_footer{
  margin-top: 30px !important;
  /* margin-left: 20px !important; */
  color: #FFFFFF;
  font-family: Poppins;
  height: 27px !important;
  width: 118px !important;
  background-color: #004A73 !important;
}

.btn_todos{
  margin-top: 30px !important;
  /* margin-left: 20px !important; */
  color: #FFFFFF;
  font-family: Poppins;
  height: 27px !important;
  width: 300px !important;
  background-color: #00A58E !important;
}

.titulo_headerNosotros{
  margin-top: 120px;
  margin-bottom: 10PX;
  /* height: 106px; */
  width: 499px;
  color: #00A58E;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 57px;
}
.subtitulo_headerNosotros{
  height: 57px;
  width: 499px;
  color: #7A7F84;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  width: 100%;
}
.containerTitulo_headerNosotros,.containerTitulo_headerContacto,.containerTitulo_headerServicios{
  display:flex;
  align-items: center;
  padding:0px 5%;
}
.container_verde{
  /* background-color: #C9DB8D !important; */
}
.letras_headerNosotros{
  width: 100%;
}
.container_headerNosotros{
  padding-bottom: 20px;
  height: 354px;
 
}
.Imagen_citarAutor{
  width: 100%;
}
.imagen_DescipcionNosotros{
  size: 20px;
}
.ContainerImagen_DescipcionNosotros{
  display:flex;
  align-items: center;
  justify-content: center;
  margin:0px 0px 10% 0px;
}
.container_descripcion{
  display:flex;
  margin-top: 100px;
  padding-left:10%;
  align-items: center;
  height: 445px;
  width: 100%;
  background-color: #C9DB8D;
}
.titulo_descripcionNosotros{
  height: 70px;
  width: 148px;
  color: white;
  font-family: Poppins;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: bold !important;
}
.parrafo_descripcionNosotros{
  height: 132px;
  color: white;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  width: 100%;
  text-align: justify;
}
.subtitulo__descripcionNosotros{
  width: 100%;
  color: #00A58E;
  font-family: Poppins;
  font-size: 38px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 20px;
}
.container_CitarAutor{
  background-image: url(./assets/img/imagen_citar.png);
  background-repeat: no-repeat;
  height:400px;
  width:100%;
  display:flex;
  align-items:center;
  padding: 5%;
  margin-bottom: 70px;
}
.titulo_citar{
  width: 100%;
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 48px;
}
.subtitulo__citar{
  height: 25px;
  width: 100%;
  color: #C9DB8D;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
}
.Espacio{
  height:100px
}

.tituloLista{
  color: #0A264B;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.33px;
  line-height: 30px;
  font-weight: bold;
}

.titulo_contacto{
  color: #424242;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.33px;
  line-height: 30px;
}
.footer_copy{
  border-top: 1px solid #E9E9E9;
  padding-top: 10px;
  width: 98%;
  color: #4A4A4A;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: left !important;
}

.separador{
  height: 100px;
}
.container_servicio_mes{
  padding: 50px;
}
.imagen_servicio_mes{
 width: 100%;
 cursor: pointer;
}
.titulo_servicio_mes{
  color: #00A58E;
  font-family: Poppins;
  font-size: 20px;
  font-weight: bold;
}
/*MEDIAS QUERYS*/
@media (max-width: 1100px){
  .imagen_vision,.imagen_mision{
    /* width: 100%; */
    height: 450px;
    width:400px;
    }
}
  @media (max-width: 900px){
  .container_mision{
    background-image: url(./assets/img/foto_mision.png);
    background-position-y: top;
    background-position-x: right;
    background-size: 100px;

  }

  .container_imagen_vision,.container_imagen_mision{
    display: none;
    }

  .container_vision{
    background-image: url(./assets/img/foto_vision.png);
    background-position-y: top;
    background-position-x: right;
    background-size: 100px;
    padding: 15px;
  }

  .container_vision_texto {
    /* padding-top: 0px; */
    padding-left: 0px;
  }
  .container_mision_texto{
    /* padding-top: 0px; */
    padding-left: 0px;
  }

  .parrafo {
    width: 100%;
  }

  .container_headerNosotros{
    padding-bottom: 40px;
  }
  .container_CitarAutor{
    padding-bottom:60px
  }
  /* .letras_headerNosotros{
    padding:0px 10px
  } */
  .titulo_headerNosotros{
    font-size: 37px;
    width:100%;
    /* line-height: 40px; */
  }
  .containerTitulo_headerNosotros{
    /* padding-top:60px; */
    text-align: center;
    background-color: #C9DB8D;
  }
  .containerTitulo_headerContacto{
    /* padding-top:60px; */
    text-align: center;
    background-color: #00A58E;
    color: white !important;
  }
  .containerTitulo_headerServicios{
    /* padding-top:60px; */
    text-align: center;
    background-color: #006F98;
    color: white !important;
  }

  .subtitulo_headerNosotros, .titulo_headerNosotros{
    /* padding-top:60px; */
    color: white !important;
  }


  .Espacio{
    height:20px
  }
  
}

@media (max-width: 600px) { 

  .SubTituloHeader{
    font-size: 30px;
    text-transform: lowercase;
  }

  .SubTituloHeader::first-letter {
    text-transform: uppercase;
  }

  .card_header {
    margin:0px 10px 50px 30px !important;
  }

  .containerHeader{
    width: 90%;
    line-height: 0px;
    padding: 200px 0px 0px 20px;
  }
  .containerNosotrosInicio{
    width: 90%;
    line-height: 0px;
    padding: 300px 0px 0px 20px;
  }
  .titulo_headerNosotros{
    font-size: 28px;
    width:100%;
    line-height: 40px;
  }
  .containerTitulo_headerNosotros,.containerTitulo_headerContacto{
    /* padding-top:40px; */
    text-align: center;
  }
  .container_descripcion{
    height:500px;
    padding:0px 5%;
  }
  .titulo_descripcionNosotros{
    height: 50px;
  }
  .subtitulo__citar{
    font-size: 15px;
  }
  .titulo_citar{
    font-size: 22px;
    line-height:30px;
    font-weight: 0;
  }


  .impactoLineas{
    display: none;
  }
  .btn_todos{
    margin: 0 auto;
  }
}

@media (max-width: 500px) { 
  .subtitulo{
    font-size: 28px;
  }
  .container_servicios_cards{
    margin-left: -25px !important;
    }
}
